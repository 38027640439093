import { handleError } from 'utils/error'
import {
  CreateOnlineReservationRequest,
  CreateOnlineReservationResponse,
  CreatePaymentTransactionRequest,
  CreateReportResponse,
  CreateTransactionRequest,
  CreateTransactionResponse,
  DiscardTransactionRequest,
  DiscardTransactionResponse,
  GetInvoicesByAppointmentRequest,
  GetInvoicesByAppointmentResponse,
  GetLocationUserBalanceRequest,
  GetLocationUserBalanceResponse,
  GetProShopItemsResponse,
  GetQuickbooksItemsResponse,
  GetTransactionTotalRequest,
  GetTransactionTotalResponse,
} from '.'
import axios from '../base'

export class TransactionService {
  private url = '/locations'

  createTransaction = async (
    fields: CreateTransactionRequest
  ): Promise<CreateTransactionResponse> => {
    const { locationId, body } = fields
    const { data } = await axios.post<CreateTransactionResponse>(
      `${this.url}/${locationId}/transactions/create`,
      body
    )
    if (data.error) {
      handleError(
        Error(
          'We encountered an error creating the transaction. Please contact support.'
        )
      )
      return {} as CreateTransactionResponse
    } else {
      return data
    }
  }

  createReport = async (
    locationId: string | number
  ): Promise<CreateReportResponse> => {
    const { data } = await axios.post<CreateReportResponse>(
      `${this.url}/${locationId}/reports/balances`
    )
    if (data.error) {
      handleError(
        Error(
          'We encountered an error creating the transaction. Please contact support.'
        )
      )
      return {} as CreateReportResponse
    } else {
      return data
    }
  }

  getLocationUserBalance = async (
    fields: GetLocationUserBalanceRequest
  ): Promise<GetLocationUserBalanceResponse> => {
    const { locationId, userId, paymentType } = fields
    const { data } = await axios.get<GetLocationUserBalanceResponse>(
      `${this.url}/${locationId}/balances/${userId}`,
      {
        params: {
          paymentType,
        },
      }
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getLocationUserBalance')
      )
      return {} as GetLocationUserBalanceResponse
    } else {
      return data
    }
  }

  createPaymentTransaction = async (
    fields: CreatePaymentTransactionRequest
  ): Promise<CreateTransactionResponse> => {
    const {
      locationId,
      clientId,
      amount,
      tax,
      invoice_ids,
      subtotal,
      payment_type,
      description
    } = fields
    const { data } = await axios.post<CreateTransactionResponse>(
      `${this.url}/${locationId}/payment/${clientId}`,
      {
        amount,
        subtotal,
        tax,
        invoice_ids,
        description: description ? description : 'Balance Payment',
        payment_method: {
          payment_method_id: null,
          payment_token_data: null,
          payment_type: payment_type || 'saved_credit_card',
        },
      }
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en createPaymentTransaction')
      )
      return {} as CreateTransactionResponse
    } else {
      return data
    }
  }

  getInvoicesByAppointmentId = async (
    fields: GetInvoicesByAppointmentRequest
  ): Promise<GetInvoicesByAppointmentResponse> => {
    const { locationId, clientId, appointmentId } = fields
    const { data } = await axios.post<GetInvoicesByAppointmentResponse>(
      `${this.url}/${locationId}/invoices/${clientId}?appointment_id=${appointmentId}`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getInvoicesByAppointmentId')
      )
      return {} as GetInvoicesByAppointmentResponse
    } else {
      const isArray = Array.isArray(data.data)
      if (!isArray) {
        //@ts-ignore
        data.data = [data.data]
      }
      return data
    }
  }

  getProShopItems = async (
    locationId: string | number
  ): Promise<GetProShopItemsResponse> => {
    const { data } = await axios.get<GetProShopItemsResponse>(
      `${this.url}/${locationId}/get_pro_shop_items`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getProShopItems')
      )
      return {} as GetProShopItemsResponse
    } else {
      return data
    }
  }

  getTransactionTotal = async (
    fields: GetTransactionTotalRequest
  ): Promise<GetTransactionTotalResponse> => {
    const { locationId, subtotal, tax_rate } = fields
    const { data } = await axios.get<GetTransactionTotalResponse>(
      `${this.url}/${locationId}/transactions/calculate?subtotal=${subtotal}&tax_rate=${tax_rate}`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getTransactionTotal')
      )
      return {} as GetTransactionTotalResponse
    } else {
      return data
    }
  }

  getQuickbooksItems = async (
    locationId: string | number
  ): Promise<GetQuickbooksItemsResponse> => {
    const { data } = await axios.get<GetQuickbooksItemsResponse>(
      `${this.url}/${locationId}/quickbooks/items`
    )
    if (data.error && data.description !== 'Refresh token not specified') {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getQuickbooksItems')
      )
      return {} as GetQuickbooksItemsResponse
    } else {
      return data
    }
  }

  createOnlineReservation = async (
    fields: CreateOnlineReservationRequest
  ): Promise<CreateOnlineReservationResponse> => {
    const { locationId, body } = fields
    const { data } = await axios.post<CreateOnlineReservationResponse>(
      `${this.url}/${locationId}/online_payment`,
      body
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en createOnlineReservation')
      )
    }
    return data
  }

  deleteTransaction = async (
    fields: DiscardTransactionRequest
  ): Promise<DiscardTransactionResponse> => {
    const { locationId, id } = fields
    const { data } = await axios.post<DiscardTransactionResponse>(
      `${this.url}/${locationId}/transactions/${id}/delete`
    )
    if (data.error) {
      handleError(
        Error(
          'We encountered an error deleting the transaction. Please contact support.'
        )
      )
      return {} as DiscardTransactionResponse
    } else {
      return data
    }
  }
}

export default new TransactionService()
