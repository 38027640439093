import { ThemedStyledProps } from 'styled-components'
import { IBaseTheme } from 'theme/styled'
import { CSSProperties } from 'react'

export type ThemeWithProps<P = Record<string, never>> = ThemedStyledProps<
  P,
  IBaseTheme
>

export const mainFont = ({ theme }: ThemeWithProps) => `
	font-family: ${theme.fonts.main};
`

export type ClassNameAndStyle = {
  className?: string
  style?: CSSProperties
}
