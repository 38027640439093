import { createAsyncThunk } from '@reduxjs/toolkit'
import transactionService from 'services/transaction/transaction.service'

export const createReport = createAsyncThunk(
  'transactions/create-report',
  transactionService.createReport
)
export const createTransaction = createAsyncThunk(
  'transactions/post-transaction',
  transactionService.createTransaction
)

export const getTransactionUserBalance = createAsyncThunk(
  'transactions/get-transaction-user-balance',
  transactionService.getLocationUserBalance
)

export const createPaymentTransaction = createAsyncThunk(
  'transactions/post-payment-transaction',
  transactionService.createPaymentTransaction
)

export const deleteTransaction = createAsyncThunk(
  'transactions/delete-transaction',
  transactionService.deleteTransaction
)

export const getInvoicesByAppointment = createAsyncThunk(
  'transactions/get-invoice-byAppointment',
  transactionService.getInvoicesByAppointmentId
)

export const getProShop = createAsyncThunk(
  'transactions/get-propShop-items',
  transactionService.getProShopItems
)
export const getTotalTransactionAmount = createAsyncThunk(
  'transactions/get-total-amount',
  transactionService.getTransactionTotal
)

export const getQuickbooks = createAsyncThunk(
  'transactions/get-quickbooks-items',
  transactionService.getQuickbooksItems
)

export const createOnlineReservation = createAsyncThunk(
  'transactions/online_payment',
  transactionService.createOnlineReservation
)
