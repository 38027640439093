import { createAsyncThunk } from '@reduxjs/toolkit'
import outreachService from 'services/outreach'

export const sendMessages = createAsyncThunk(
  'outreach/send-message',
  outreachService.sendMessages
)

export const sendEmails = createAsyncThunk(
  'outreach/send-emails',
  outreachService.sendEmails
)
