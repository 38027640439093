import { createAsyncThunk } from '@reduxjs/toolkit'
import appointmentService from 'services/appointment'

export const getAppointmentCategories = createAsyncThunk(
  'appointment/get-appointments-categories',
  appointmentService.getAppointmentCategories
)

export const getLocation = createAsyncThunk(
  'appointment/get-location',
  appointmentService.getLocation
)
export const updateLocation = createAsyncThunk(
  'appointment/update-location',
  appointmentService.updateLocation
)

export const getAppointments = createAsyncThunk(
  'appointment/get-appointments',
  appointmentService.getAppointments
)
export const getUserAppointments = createAsyncThunk(
  'appointment/get-user-appointments',
  appointmentService.getUserAppointments
)

export const getSingleAppointment = createAsyncThunk(
  'appointment/get-single-appointment',
  appointmentService.getSingleAppointment
)

export const getDiscardedAppointments = createAsyncThunk(
  'appointment/get-discarded-appointments',
  appointmentService.getDiscardedAppointments
)

export const postRestoreAppointment = createAsyncThunk(
  'appointment/post-restore-appointments',
  appointmentService.restoreAppointment
)

export const updateAppointment = createAsyncThunk(
  'appointment/update-appointments',
  appointmentService.updateAppointment
)

export const getAppointmentColors = createAsyncThunk(
  'appointment/get-appointment-colors',
  appointmentService.getAppointmentReservationColors
)

export const deleteAppointment = createAsyncThunk(
  'appointment/delete-appointment',
  appointmentService.deleteAppointment
)

export const getOpenCourts = createAsyncThunk(
  'appointment/get-open-courts',
  appointmentService.getOpenCourts
)