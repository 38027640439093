import { createSlice } from '@reduxjs/toolkit'
import APILoadingStatus from 'types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  createTransaction,
  createPaymentTransaction,
  getTransactionUserBalance,
  getInvoicesByAppointment,
  getProShop,
  getTotalTransactionAmount,
  getQuickbooks,
  createOnlineReservation,
  createReport,
  deleteTransaction
} from './transactions.actions'
import cogoToast from 'cogo-toast'
import { TransactionInvoice } from 'models/transaction.model'
import { SelectTypes } from 'components/Select/Select.props'
import {
  ProShopCategory,
  ProShopCategoryItem,
  ProShopItem,
} from 'models/location'
import { UserPastPurchase } from 'models/user.model'

export type TransactionState = {
  createLoading: APILoadingStatus
  deleteLoading: APILoadingStatus
  createOnlineReservationLoading: APILoadingStatus
  loading: APILoadingStatus
  createPaymentTransactionLoading: APILoadingStatus
  loadingInvoice: APILoadingStatus
  loadingPrice: APILoadingStatus
  loadingQuickbooks: APILoadingStatus
  invoices: TransactionInvoice[]
  balance: number | null
  transactionType: SelectTypes[]
  categories: ProShopCategory[]
  categoriesDropdown: SelectTypes[]
  proShopItems: ProShopItem[]
  totalAmount: number
  subTotalAmount: number
  taxAmount: number
  currentTransaction: UserPastPurchase | null
  quickbooksDropdown: SelectTypes[]
}

const initialState: TransactionState = {
  createLoading: APILoadingStatus.Idle,
  deleteLoading: APILoadingStatus.Idle,
  createOnlineReservationLoading: APILoadingStatus.Idle,
  loadingInvoice: APILoadingStatus.Idle,
  loading: APILoadingStatus.Idle,
  createPaymentTransactionLoading: APILoadingStatus.Idle,
  loadingPrice: APILoadingStatus.Idle,
  loadingQuickbooks: APILoadingStatus.Idle,
  invoices: [],
  transactionType: [],
  categories: [],
  categoriesDropdown: [],
  proShopItems: [],
  balance: null,
  totalAmount: 0,
  subTotalAmount: 0,
  taxAmount: 0,
  currentTransaction: null,
  quickbooksDropdown: [],
}

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    cleanAmounts(state) {
      state.totalAmount = 0
      state.subTotalAmount = 0
    },
    setCurrentTransaction(state, action) {
      state.currentTransaction = action.payload
    },
  },
  extraReducers: builder => {
    asyncReducers(
      builder,
      createTransaction,
      'createLoading',
      (_state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
      }
    ),
      asyncReducers(
        builder,
        deleteTransaction,
        'deleteLoading',
        (_state, action) => {
          if (action.payload.error) {
            cogoToast.error(action.payload.error, {
              position: 'top-right',
            })
            return
          }
        }
      ),
      asyncReducers(
        builder,
        createOnlineReservation,
        'createOnlineReservationLoading'
      ),
      asyncReducers(builder, createReport, 'loading', (_state, action) => {
        if (action.payload.data.status === 'Processing') {
          cogoToast.success('We are processing your report', {
            position: 'top-right',
          })
        }
      }),
      asyncReducers(
        builder,
        createPaymentTransaction,
        'createPaymentTransactionLoading',
        (_state, action) => {
          if (action.payload.error) {
            cogoToast.error(action.payload.error, {
              position: 'top-right',
            })
          }
        }
      ),
      asyncReducers(
        builder,
        getInvoicesByAppointment,
        'loading',
        (state, action) => {
          if (action.payload.error) {
            cogoToast.error(action.payload.error, {
              position: 'top-right',
            })
            return
          }
          if (action.payload.data) {
            state.invoices = action.payload.data
          }
        }
      ),
      asyncReducers(builder, getProShop, 'loading', (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
        if (action.payload.data) {
          const { data } = action.payload
          const categoriesList: SelectTypes[] = []
          const types: SelectTypes[] = []
          data.forEach(element => {
            if (element) {
              types.push({ label: element.name, value: element.name })
              if (element.categories) {
                if (element.categories) {
                  element.categories.forEach(cat => {
                    const newItems: ProShopCategoryItem[] = []
                    cat.items.forEach(item => {
                      item.quantity = 1
                      newItems.push(item)
                    })
                    cat.items = newItems
                  })
                }
                state.categories = element.categories
                element.categories.forEach(category =>
                  categoriesList.push({
                    label: category.name,
                    value: category.program_id.toString(),
                  })
                )
              }
              if (element.items) {
                state.proShopItems = element.items.map(item => {
                  item.quantity = 1
                  return item
                })
              }
            }
          })
          state.transactionType = types as SelectTypes[]
          state.categoriesDropdown = categoriesList as SelectTypes[]
        }
      })
    asyncReducers(
      builder,
      getTransactionUserBalance,
      'loading',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
        }
        if (action.payload.data) {
          const { balance, invoices } = action.payload.data
          const newInvoices =
            invoices.length > 0
              ? invoices.map(invoice => {
                invoice.remove = false
                return invoice
              })
              : []
          state.balance = balance
          state.invoices = newInvoices
        }
      }
    )
    asyncReducers(
      builder,
      getTotalTransactionAmount,
      'loadingPrice',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
        }
        if (action.payload.data) {
          const { total, subtotal, total_tax } = action.payload.data
          state.totalAmount = total
          state.subTotalAmount = subtotal
          state.taxAmount = total_tax
        }
      }
    )
    asyncReducers(
      builder,
      getQuickbooks,
      'loadingQuickbooks',
      (state, action) => {
        if (
          action.payload.error &&
          action.payload.description !== 'Refresh token not specified'
        ) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
        }
        if (action.payload.data) {
          state.quickbooksDropdown = action.payload.data.quickbooks_items.map(
            item => {
              return {
                label: item.name,
                value: item.id.toString(),
              }
            }
          )
        }
      }
    )
  },
})

export default transactionSlice.reducer
export const transactionActions = {
  ...transactionSlice.actions,
  createTransaction,
  getTransactionUserBalance,
  createPaymentTransaction,
  getInvoicesByAppointment,
  getProShop,
  getTotalTransactionAmount,
  getQuickbooks,
  createOnlineReservation,
  createReport,
  deleteTransaction
}
