import merge from 'lodash/merge'
import { THEME } from './enums'

const COLORS = [
  'white',
  'black',
  'blackSecondary',
  'primary',
  'primaryDarker',
  'primaryDisabled',
  'primaryAccent',
  'secondary',
  'secondaryDarker',
  'secondaryDisabled',
  'secondaryAccent',
  'neutral',
  'neutralDarker',
  'neutralDisabled',
  'fontMain',
  'fontSecondary',
  'border',
  'borderSecondary',
  'accent',
  'backgroundMain',
  'backgroundPrimary',
  'backgroundSecundary',
  'backgroundGrayLigth',
  'backgroundGrayDark',
  'backgroundGrayDarkAccent',
  'focus',
  'info',
  'warning',
  'success',
  'successSecondary',
  'third',
  'error',
  'errorSecondary',
] as const
export type Colors = typeof COLORS[number]
export function isColor(key: string): key is Colors {
  return COLORS.includes(key as Colors)
}

const colors: Record<Colors, string> = {
  white: 'white',
  black: '#000000',
  blackSecondary: '#1A1A1A',
  primary: '#1600FF',
  primaryDarker: '#1202b7',
  primaryDisabled: 'rgba(22, 0, 255, 0.5)',
  primaryAccent: '#1202B7',
  secondary: '#F45353',
  secondaryAccent: '#EC1C1C',
  secondaryDarker: '#ec1c1c',
  secondaryDisabled: 'rgba(244, 83, 83, 0.5)',
  third: ' #00E7D7',
  neutral: ' #9098A1',
  neutralDarker: '#858c94',
  neutralDisabled: 'rgba(144, 152, 161, 0.7)',

  fontMain: '#231f20',
  fontSecondary: '#595959',

  border: '#f0eff0',
  backgroundMain: '#F8F8F8',
  borderSecondary: '#D8D8D8',
  backgroundPrimary: '#FFFFFF',
  backgroundSecundary: '#F6F4F3',
  backgroundGrayLigth: '#F4F6F9',
  backgroundGrayDark: '#C4C4C4',
  backgroundGrayDarkAccent: '#798087',

  accent: '#fff5d6',
  focus: '#00A79D',

  info: '#2E5AAC',
  warning: '#B95000',
  success: '#287D3C',
  successSecondary: '#EDF9F0',
  error: '#DA1414',
  errorSecondary: '#FEEFEF',
}

//Type and Const List for Fonts
export const FONTS = ['main', 'secondary'] as const
export type Font = typeof FONTS[number]

//Fonts for Text Properties
export const fontsMapped: Record<Font, string> = {
  main: 'Work Sans',
  secondary: 'Poppins',
}

export const fonts = {
  main: 'Work Sans',
  secondary: 'Poppins',
}

export const baseTheme = {
  colors,
  fonts,
}

const lightTheme = { ...baseTheme }

const darkTheme = merge({}, baseTheme, {
  // TODO: implement dark theme
})

export const themes = {
  [THEME.Light]: lightTheme,
  [THEME.Dark]: darkTheme,
}

export interface IBaseTheme extends Readonly<typeof baseTheme> {}

declare module 'styled-components' {
  export interface DefaultTheme extends IBaseTheme {}
}
