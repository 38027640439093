import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SelectTypes } from 'components/Select/Select.props'
import {
  DiscardedAppointment,
  AppointmentCategory,
  UserAppointment,
  BookingNowData,
} from 'models/appointment.model'
import { DayValue } from 'react-modern-calendar-datepicker'
import { LocationDetail } from 'models/location'
import {
  UserAppointmentResponse,
  AppointmentsResponse,
  DiscardedAppointmentResponse,
  locationResponse,
  getSingleAppointmentResponse,
  AppointmentDetail,
  ReservationResponse,
  getOpenCourtsResponse,
} from 'services/appointment'
import APILoadingStatus from 'types/api-loading-status'
import { EventCalendar } from 'types/event-calendar-types'
import asyncReducers from '../../../utils/async-reducers'
import {
  getDiscardedAppointments,
  postRestoreAppointment,
  getUserAppointments,
  getAppointments,
  getLocation,
  updateLocation,
  getAppointmentCategories,
  getSingleAppointment,
  updateAppointment,
  getAppointmentColors,
  deleteAppointment,
  getOpenCourts,
} from './appointments.actions'
import toast from 'cogo-toast'
import { sortBy } from 'lodash'

export type AppointmentState = {
  loading: APILoadingStatus
  loadingAppointment: APILoadingStatus
  loadingOpenCourts: APILoadingStatus
  discarded: DiscardedAppointment[] | null
  userAppointments: UserAppointment[]
  currentPage: number
  totalAppointments: number
  appointments: EventCalendar[] | null
  location: LocationDetail | null
  courts: { id: string; title: string }[] | null
  categories: SelectTypes[]
  currentAppointment: AppointmentDetail | null
  reservationColors: SelectTypes[]
  bookingNowData: BookingNowData | null
}

const initialState: AppointmentState = {
  loading: APILoadingStatus.Idle,
  loadingAppointment: APILoadingStatus.Idle,
  loadingOpenCourts: APILoadingStatus.Idle,
  discarded: null,
  userAppointments: [],
  currentPage: 1,
  totalAppointments: 0,
  appointments: null,
  location: null,
  courts: null,
  categories: [],
  currentAppointment: null,
  reservationColors: [],
  bookingNowData: null,
}

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    clearAppointmentPagination(state) {
      state.currentPage = 1
      state.totalAppointments = 0
      state.userAppointments = []
      state.discarded = null
    },
    setBookingNowData(state, action: PayloadAction<BookingNowData>) {
      state.bookingNowData = action.payload
    },
  },
  extraReducers: builder => {
    asyncReducers(
      builder,
      getAppointmentColors,
      'loading',
      (state, action: PayloadAction<ReservationResponse>) => {
        if (action.payload.data) {
          const options: SelectTypes[] = []
          Object.entries(action.payload.data).forEach(([key, value]) =>
            options.push({ label: key, color: value, value })
          )
          state.reservationColors = options
        }
      }
    ),
      asyncReducers(
        builder,
        getDiscardedAppointments,
        'loading',
        (state, action: PayloadAction<DiscardedAppointmentResponse>) => {
          if (action.payload) {
            state.discarded = action.payload.data
            if (action.payload.description) {
              state.totalAppointments = action.payload.description.total_count
            }
          }
        }
      ),
      asyncReducers(
        builder,
        getUserAppointments,
        'loading',
        (state, action: PayloadAction<UserAppointmentResponse>) => {
          if (action.payload) {
            const { data } = action.payload
            if (data.length > 0) {
              state.userAppointments = data
            } else {
              state.userAppointments = []
            }
            if (action.payload.description) {
              state.totalAppointments = action.payload.description.total_count
            }
          }
        }
      ),
      asyncReducers(
        builder,
        getLocation,
        'loading',
        (state, action: PayloadAction<locationResponse>) => {
          if (action.payload) {
            if (action.payload.data.courts.length > 0) {
              const courtsOrdered = sortBy(
                action.payload.data.courts,
                court => court.id
              )
              const newCourts = courtsOrdered.map(court => {
                return {
                  id: court.id ? court.id.toString() : '',
                  title: court.name,
                }
              })
              state.courts = newCourts
            }
            state.location = action.payload.data
          }
        }
      ),
      asyncReducers(
        builder,
        updateLocation,
        'loading',
        (state, action: PayloadAction<locationResponse>) => {
          if (action.payload) {
            if (action.payload.error) {
              toast.error(action.payload.error, { position: 'top-right' })
              return
            }
            if (action.payload.data.courts.length > 0) {
              const courtsOrdered = sortBy(
                action.payload.data.courts,
                court => court.id
              )
              const newCourts = courtsOrdered.map(court => {
                return {
                  id: court.id ? court.id.toString() : '',
                  title: court.name,
                }
              })
              state.courts = newCourts
            }
            state.location = action.payload.data
            toast.success('The location was successfully updated', {
              position: 'top-right',
            })
          }
        }
      ),
      asyncReducers(
        builder,
        getAppointmentCategories,
        'loading',
        (state, action: PayloadAction<AppointmentCategory[]>) => {
          if (action.payload?.length > 0) {
            const options = action.payload
            const formatCategories = options.map(category => {
              return { label: category.name, value: category.id.toString() }
            })
            state.categories = formatCategories
          }
        }
      ),
      asyncReducers(
        builder,
        getSingleAppointment,
        'loadingAppointment',
        (state, action: PayloadAction<getSingleAppointmentResponse>) => {
          if (action.payload) {
            state.currentAppointment = action.payload.data
          }
        }
      ),
      asyncReducers(
        builder,
        updateAppointment,
        'loading',
        (state, action: PayloadAction<UserAppointmentResponse>) => {
          if (action.payload.error) {
            return toast.error(action.payload.error, { position: 'top-right' })
          }
          toast.success('Appointment Updated', {
            position: 'top-right',
          })
        }
      ),
      asyncReducers(
        builder,
        deleteAppointment,
        'loadingAppointment',
        (state, action: PayloadAction<UserAppointmentResponse>) => {
          if (action.payload.error) {
            toast.error(action.payload.error, { position: 'top-right' })
          }
        }
      )
    asyncReducers(
      builder,
      getAppointments,
      'loading',
      (state, action: PayloadAction<AppointmentsResponse>) => {
        if (action.payload && action.payload.data) {
          if (action.payload.data) {
            const events = [...action.payload.data]
            if (events.length > 0) {
              const newEvents: EventCalendar[] = []
              events.map(event => {
                if (event.courts) {
                  event.courts.forEach(courtId =>
                    newEvents.push({
                      ...event,
                      start: new Date(event.start),
                      end: new Date(event.end),
                      resourceId: courtId.toString(),
                    })
                  )
                }
              })
              state.appointments = newEvents
              return
            }
            state.appointments = null
          }
        }
      }
    )
    asyncReducers(
      builder,
      getOpenCourts,
      'loadingOpenCourts',
      (state, action: PayloadAction<getOpenCourtsResponse>) => {
        if (action.payload.error) {
          toast.error(action.payload.error, { position: 'top-right' })
        }
      }
    )
    asyncReducers(builder, postRestoreAppointment, 'loading')
  },
})

export default appointmentSlice.reducer

export const appointmentActions = {
  ...appointmentSlice.actions,
  ...appointmentSlice.reducer,
  getDiscardedAppointments,
  getUserAppointments,
  postRestoreAppointment,
  getAppointments,
  getLocation,
  getAppointmentCategories,
  getSingleAppointment,
  getAppointmentColors,
  updateAppointment,
  updateLocation,
  deleteAppointment,
  getOpenCourts,
}
