import {
  AppointmentCategoriesResponse,
  AppointmentCategory,
} from 'models/appointment.model'
import { handleError } from 'utils/error'
import {
  AppointmentsRequest,
  AppointmentsResponse,
  DiscardedAppointmentRequest,
  DiscardedAppointmentResponse,
  getSingleAppointmentRequest,
  getSingleAppointmentResponse,
  locationResponse,
  ReservationResponse,
  RestoreAppointmentRequest,
  UpdateAppointmentRequest,
  UserAppointmentResponse,
  UserAppointmentsRequest,
} from '.'
import axios from '../base'
import {
  DeleteAppointmentRequest,
  getOpenCourtsRequest,
  getOpenCourtsResponse,
  UpdateLocation,
} from './appointment.types'

export class AppointmentService {
  private url = '/locations'

  getAppointmentCategories = async (
    locationId: string
  ): Promise<AppointmentCategory[]> => {
    const { data } = await axios.get<AppointmentCategoriesResponse>(
      `${this.url}/${locationId}/reservation_categories`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getAppointmentCategories')
      )
      return [] as AppointmentCategory[]
    } else {
      return data.data
    }
  }

  getLocation = async (locationId: string): Promise<locationResponse> => {
    console.log('axios.', axios.defaults.headers)
    const { data } = await axios.get<locationResponse>(
      `${this.url}/${locationId}`,
      {
        headers: { Authorization: axios.defaults.headers.common.Authorization },
      }
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getLocation')
      )
      return {} as locationResponse
    } else {
      return data
    }
  }

  updateLocation = async (
    fields: UpdateLocation
  ): Promise<locationResponse> => {
    const { locationId, body } = fields
    const { data } = await axios.post<locationResponse>(
      `${this.url}/${locationId}`,
      body
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en updateLocation')
      )
      return {} as locationResponse
    } else {
      return data
    }
  }

  getAppointments = async (
    fields: AppointmentsRequest
  ): Promise<AppointmentsResponse> => {
    const { locationId, startDate, endDate } = fields
    const { data } = await axios.get<AppointmentsResponse>(
      `${this.url}/${locationId}/appointments?start_date=${startDate}&end_date=${endDate}&limit=250`
    )
    if (data.error) {
      handleError(Error('Error in getAppointments'))
      return {} as AppointmentsResponse
    } else {
      const isArray = Array.isArray(data.data)
      if (!isArray) {
        //@ts-ignore
        data.data = [data.data]
      }
      return data
    }
  }

  getUserAppointments = async (
    fields: UserAppointmentsRequest
  ): Promise<UserAppointmentResponse> => {
    const { locationId, userId, page, limit } = fields
    const { data } = await axios.get<UserAppointmentResponse>(
      `${this.url}/${locationId}/appointments?user_id=${userId}&page=${page}&limit=${limit}&calendar_formatting=false`
    )
    if (data.error) {
      handleError(Error('Error in getUserAppointments'))
      return {} as UserAppointmentResponse
    } else {
      return data
    }
  }

  getDiscardedAppointments = async (
    fields: DiscardedAppointmentRequest
  ): Promise<DiscardedAppointmentResponse> => {
    const { locationId, page, limit } = fields
    const { data } = await axios.get<DiscardedAppointmentResponse>(
      `${this.url}/${locationId}/appointments?discarded=true&page=${page}&limit=${limit}`
    )
    if (data.error) {
      handleError(Error('Error in getDiscardedAppointments'))
      return {} as DiscardedAppointmentResponse
    } else {
      return data
    }
  }

  getSingleAppointment = async (
    fields: getSingleAppointmentRequest
  ): Promise<getSingleAppointmentResponse> => {
    const { locationId, appointmentId } = fields
    const { data } = await axios.get<getSingleAppointmentResponse>(
      `${this.url}/${locationId}/appointments/${appointmentId}?calendar_formatting=false`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getSingleAppointment')
      )
      return {} as getSingleAppointmentResponse
    } else {
      return data
    }
  }

  restoreAppointment = async (
    fields: RestoreAppointmentRequest
  ): Promise<DiscardedAppointmentResponse> => {
    const { id, undiscard, locationId } = fields
    const { data } = await axios.post<DiscardedAppointmentResponse>(
      `${this.url}/${locationId}/appointments/update/${id}?undiscard=true`,
      { undiscard: undiscard }
    )
    if (data.error) {
      handleError(Error('Error in restoreAppointment'))
      return {} as DiscardedAppointmentResponse
    } else {
      return data
    }
  }

  getAppointmentReservationColors = async (
    locationId: string
  ): Promise<ReservationResponse> => {
    const { data } = await axios.get<ReservationResponse>(
      `${this.url}/${locationId}/appointment_categories`
    )
    if (data.error) {
      handleError(Error('Error in getAppointmentReservationColors'))
      return {} as ReservationResponse
    } else {
      return data
    }
  }

  updateAppointment = async (
    fields: UpdateAppointmentRequest
  ): Promise<UserAppointmentResponse> => {
    let search = ''
    const {
      notes,
      appointmentId,
      locationId,
      start_date,
      end_date,
      court_ids,
    } = fields
    if (notes) {
      search = search + `&notes=${notes}`
    }
    if (start_date && end_date) {
      search = search + `&start_date=${start_date}&end_date=${end_date}`
    }
    if (court_ids) {
      search = search + `&court_ids=${court_ids}`
    }
    const { data: response } = await axios.post<UserAppointmentResponse>(
      `${this.url}/${locationId}/appointments/update/${appointmentId}?${search}`
    )
    if (response.error) {
      handleError(Error('Error in updateAppointment'))
      return {} as UserAppointmentResponse
    } else {
      return {
        ...response,
        appointmentId,
        locationId,
        start_date: start_date as string,
        end_date: end_date as string,
        court_ids: court_ids as number[],
      }
    }
  }

  deleteAppointment = async (
    fields: DeleteAppointmentRequest
  ): Promise<any> => {
    const { appointmentId, locationId } = fields
    const { data } = await axios.post<DiscardedAppointmentResponse>(
      `${this.url}/${locationId}/appointments/delete/${appointmentId}`
    )
    if (data.error) {
      handleError(Error('Error in deleteAppointment'))
      return {} as DiscardedAppointmentResponse
    } else {
      return data
    }
  }

  getOpenCourts = async (
    fields: getOpenCourtsRequest
  ): Promise<getOpenCourtsResponse> => {
    const { locationId, date, court_id } = fields
    let search = `${this.url}/${locationId}/availabilities/${date}?player_calendar_formatting=true`
    if (court_id) {
      search = search + `&court_id=${court_id}`
    }
    const { data } = await axios.get<getOpenCourtsResponse>(search)
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error en getOpenCourts')
      )
      return {} as getOpenCourtsResponse
    } else {
      return data
    }
  }
}

export default new AppointmentService()
