import { handleError } from 'utils/error'
import { UpdateClientByIdRequest } from '.'
import axios from '../base'
import {
  ClientListRequest,
  ClientListResponse,
  ClientByIdRequest,
  GetClientByIdResponse,
} from './client.types'
export class ClientService {
  private url = '/locations'

  getClientList = async (
    fields: ClientListRequest
  ): Promise<ClientListResponse> => {
    const { locationId, page, limit, search_text } = fields
    const { data } = await axios.get<ClientListResponse>(
      `${this.url}/${locationId}/clients?page=${page}&limit=${limit}${
        search_text ? `&search_text=${search_text}` : ''
      }`
    )
    if (data.error) {
      handleError(Error("Error in getClientList"));
      return {} as ClientListResponse;
    } else {
      return data;
    }
  }

  getClientById = async (
    fields: ClientByIdRequest
  ): Promise<GetClientByIdResponse> => {
    const { clientId } = fields
    const { data } = await axios.get<GetClientByIdResponse>(
      `/users/${clientId}`
    )
    if (data.error) {
      handleError(Error("Error in getClientById"));
      return {} as GetClientByIdResponse;
    } else {
      return data;
    }
  }

  updateClientById = async (
    fields: UpdateClientByIdRequest
  ): Promise<GetClientByIdResponse> => {
    const {
      clientId,
      name,
      last_name,
      phone,
      address,
      secondary_address,
      city,
      state,
      zipcode,
      notes,
      email,
    } = fields
    const { data } = await axios.post<GetClientByIdResponse>(
      `/users/${clientId}/update?name=${name}&last_name=${last_name}&phone=${phone}&address=${address}&secondary_address=${secondary_address}&city=${city}&state=${state}&zipcode=${zipcode}&notes=${notes}&email=${email}`
    )
    if (data.error) {
      handleError(Error("Error in updateClientById"));
      return {} as GetClientByIdResponse;
    } else {
      return data;
    }
  }
}

export default new ClientService()
