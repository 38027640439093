import { createAsyncThunk } from '@reduxjs/toolkit'
import authService from 'services/auth'

export const signIn = createAsyncThunk('auth/sign-in', authService.signIn)
export const signUp = createAsyncThunk('auth/sign-up', authService.signUp)
export const createClient = createAsyncThunk(
  'auth/create-client',
  authService.createClient
)
export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  authService.resetPassword
)
