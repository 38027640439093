import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslations from './en.json'
import esTranslations from './es.json'

const resources = {
  en: { translation: enTranslations },
  es: { translation: esTranslations },
}

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
})

export default i18n
