import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import { PERSIST_TIMEOUT, CACHE_VERSION, PERSIST_KEY } from '../constants/store'
// Reducers
import auth from './slices/auth'
import outreach from './slices/outreach'
import clients from './slices/clients'
import appointments from './slices/appointments'
import programs from './slices/programs'
import layout from 'store/slices/layout'
import user from 'store/slices/users'
import transaction from 'store/slices/transactions'

export const isProduction = process.env.NODE_ENV === 'production'

const appReducer = combineReducers({
  auth,
  outreach,
  layout,
  clients,
  programs,
  appointments,
  user,
  transaction,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOG_OUT') {
    state = undefined
    localStorage.clear()
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export const persistConfig = {
  storage,
  key: PERSIST_KEY,
  timeout: PERSIST_TIMEOUT,
  keyPrefix: CACHE_VERSION,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
]

if (!isProduction) {
  const logger = createLogger()
  middleware.push(logger)
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !isProduction,
  middleware,
})

export const persistor = persistStore(store)
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>
export const useAppState = <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean
) => useSelector(selector, equalityFn)
export const useAppDispatch = () => useDispatch<AppDispatch>()
