import React, { lazy, Suspense } from 'react'
import GlobalStyle from './GlobalStyle'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store'
import { initAmplitude } from 'amplitude/amplitude'
import ThemeProvider from 'theme'
import PageLoader from 'components/PageLoader/PageLoader'

// @ts-ignore - Ignored because its @type does not exist
import HttpsRedirect from 'react-https-redirect'

const AppModule = lazy(() => import('modules/App'))

function App() {
  initAmplitude()
  return (
    <HttpsRedirect>
      <BrowserRouter>
        <ThemeProvider>
          <StoreProvider store={store}>
            <PersistGate persistor={persistor} loading={null}>
              <GlobalStyle />
              <Switch>
                <Suspense fallback={<PageLoader />}>
                  <AppModule />
                </Suspense>
              </Switch>
            </PersistGate>
          </StoreProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HttpsRedirect>
  )
}

export default App
