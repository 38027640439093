import Spinner from 'components/Spinner'
import React from 'react'
import { PageLoaderContainer } from './PageLoader.styled'

const PageLoader: React.FC = () => (
  <PageLoaderContainer>
    <Spinner />
  </PageLoaderContainer>
)

export default PageLoader
