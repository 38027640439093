import { createAsyncThunk } from '@reduxjs/toolkit'
import userService from 'services/user/user.service'
import waiverService from 'services/waiver/waiver.service'

export const setUserFamily = createAsyncThunk(
  'users/set-user-family',
  userService.setUserFamily
)
export const getUserAppointments = createAsyncThunk(
  'users/get-users-appointments',
  userService.getUserAppointments
)
export const getUserProfile = createAsyncThunk(
  'users/get-users-profile',
  userService.getUserProfile
)
export const getUserTransactionsByLocation = createAsyncThunk(
  'users/get-users-transactions',
  userService.getUserTransactionsByLocationId
)
export const getUserBalance = createAsyncThunk(
  'users/get-users-balance',
  userService.getUserBalance
)
export const getWaiverStatusForUser = createAsyncThunk(
  'waiver/get-waiver-status',
  waiverService.getWaiverStatusForUser
)
export const postWaiverStatusForUser = createAsyncThunk(
  'waiver/post-waiver-status',
  waiverService.postWaiverStatusForUser
)
