import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import APILoadingStatus from 'types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import cogoToast from 'cogo-toast'
import {
  getProgramById,
  createProgram,
  getPrograms,
  getProgramScheduleById,
  createProgramRegistration,
  getPublicPrograms,
} from './programs.actions'
import { Program, SingleProgram, PublicProgram } from 'models/program.model'
import { AppointmentDetail } from 'services/appointment'

// @TODO - Add Public Programs types

export type ProgramState = {
  programStatus: APILoadingStatus
  currentProgramStatus: APILoadingStatus
  programs: Program[] | null
  currentProgram: SingleProgram | null
  programSchedule: AppointmentDetail[] | null
  currentPage: number
  totalPrograms: number
  publicPrograms: PublicProgram[] | null
}

const initialState: ProgramState = {
  programStatus: APILoadingStatus.Idle,
  currentProgramStatus: APILoadingStatus.Idle,
  programs: null,
  currentProgram: null,
  programSchedule: null,
  currentPage: 1,
  totalPrograms: 0,
  publicPrograms: null,
}

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    cleanCurrentProgram(state) {
      state.currentProgram = null
      state.programSchedule = null
    },
  },
  extraReducers: builder => {
    asyncReducers(builder, getPrograms, 'programStatus', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
        return
      }
      state.totalPrograms = action.payload.description.total_count
      if (action.payload.description.total_count === 1) {
        return (state.programs = [(action.payload.data as unknown) as Program])
      }
      state.programs = action.payload.data
    })
    asyncReducers(
      builder,
      getProgramById,
      'currentProgramStatus',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
        state.currentProgram = action.payload.data
      }
    )
    asyncReducers(
      builder,
      createProgram,
      'currentProgramStatus',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
        cogoToast.success('Your program was created.', {
          position: 'top-right',
        })
      }
    )
    asyncReducers(
      builder,
      getProgramScheduleById,
      'currentProgramStatus',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
        state.programSchedule = action.payload.data
      }
    )
    asyncReducers(
      builder,
      createProgramRegistration,
      'programStatus',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
          return
        }
      }
    ),
      asyncReducers(
        builder,
        getPublicPrograms,
        'publicPrograms',
        (state, action) => {
          if (action.payload.error) {
            cogoToast.error(action.payload.error, {
              position: 'top-right',
            })
            return
          }
          state.publicPrograms = action.payload.data
        }
      )
  },
})

export default programSlice.reducer
export const programActions = {
  ...programSlice.actions,
  ...programSlice.reducer,
  getPrograms,
  getProgramById,
  getProgramScheduleById,
  getPublicPrograms,
  createProgramRegistration,
  createProgram,
}
