import { UserProfile } from 'models/user.model'
import { handleError } from 'utils/error'
import axios from '../base'
import {
  UserBalanceRequest,
  UserBalanceResponse,
  UserFamilyRequest,
  UserTransactionsByLocationRequest,
  UserTransactionsByLocationResponse,
} from './user.types'
import { Appointment } from 'services/appointment'

export class UserService {
  private url = '/users'

  setUserFamily = async (
    fields: UserFamilyRequest
  ): Promise<UserProfile | undefined> => {
    const { data } = await axios.post<{
      data: UserProfile
      error: boolean
      description: string
    }>(`${this.url}/update`, fields)
    if (data.error) {
      handleError(Error(data.description))
    } else {
      return data.data
    }
  }
  getUserAppointments = async (userId: number): Promise<Appointment[]> => {
    const { data } = await axios.get<{ data: Appointment[]; error: boolean }>(
      `${this.url}/${userId}?appointments?page=0&limit=10`
    )
    if (data.error) {
      handleError(Error('Error fetching users appointments'))
      return []
    } else {
      return data.data
    }
  }

  getUserProfile = async (userId: number): Promise<UserProfile> => {
    const { data } = await axios.get<{ data: UserProfile }>(
      `${this.url}/${userId}?is_profile=true`
    )
    if (data.data.error) {
      handleError(Error('Error fetching user profile'))
      return {} as UserProfile
    } else {
      return data.data
    }
  }

  getUserTransactionsByLocationId = async ({
    locationId,
    userId,
  }: UserTransactionsByLocationRequest): Promise<UserTransactionsByLocationResponse> => {
    const { data } = await axios.get<UserTransactionsByLocationResponse>(
      `locations/${locationId}/invoices/${userId}`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error fetching location')
      )
      return {} as UserTransactionsByLocationResponse
    } else {
      return data
    }
  }

  getUserBalance = async ({
    locationId,
    userId,
  }: UserBalanceRequest): Promise<UserBalanceResponse> => {
    const { data } = await axios.get<UserBalanceResponse>(
      `/locations/${locationId}/balances/${userId}`
    )
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error fetching user balance')
      )
      return {} as UserBalanceResponse
    } else {
      return data
    }
  }
}

export default new UserService()
