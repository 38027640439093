import toast from 'cogo-toast'

export function handleError(error: Error) {
  console.error(`[handleError]: `, error);
  showErrorMessage(error);
  // TODO: log to Sentry
}
export function showErrorMessage(error: Error | string) {
  toast.error(`${error}`, {position: 'top-right'})
}

export const isNetworkError = (error?: Error) =>
  Boolean(error && error.message && error.message === 'Network Error')
