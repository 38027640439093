import { createGlobalStyle } from 'styled-components'
import { xs, md } from 'utils/breakpoints'
import { mainFont } from 'utils/styled'

const GlobalStyle = createGlobalStyle`
  body {
    ${mainFont}
    ${xs`font-size: 14px;`}
    ${md`
font-size: 15px;
    `}
    margin: 0;
  }
`

export default GlobalStyle
