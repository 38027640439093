import { createSlice } from '@reduxjs/toolkit'
import APILoadingStatus from 'types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import { signIn, signUp, createClient, resetPassword } from './auth.actions'
import { User } from 'types/models'
import toast from 'cogo-toast'
import { setToken } from 'services/tokenManager'

export type AuthState = {
  token: string | null
  user: User | null
  userLocations: number[]
  authStatus: APILoadingStatus
  authError: Record<string, unknown> | null
  isLogged: boolean
}

const initialState: AuthState = {
  token: null,
  user: null,
  authStatus: APILoadingStatus.Idle,
  authError: null,
  isLogged: false,
  userLocations: [],
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cleanAuth(state) {
      state.token = null
      state.user = null
      state.authError = null
      state.isLogged = false
      state.userLocations = []
    },
  },
  extraReducers: builder => {
    asyncReducers(builder, signIn, 'authStatus', (state, action) => {
      if (!action.payload.error && action.payload.data) {
        setToken(action.payload.data.token);
        state.token = action.payload.data.token
        state.user = action.payload.data.user

        if (
          action.payload.user_data &&
          action.payload.user_data.location_id &&
          action.payload.user_data.location_id.length > 0
        ) {
          state.userLocations = action.payload.user_data.location_id
          state.user.facility_owner = true
        } else {
          state.user.facility_owner = false
        }

        state.isLogged = true
      }
      if (action.payload.error) {
        toast.error(
          'There was an error with your email / password combination. Please try again.',
          { position: 'top-right' }
        )
      }
    })
    asyncReducers(builder, signUp, 'authStatus', (state, action) => {
      if (!action.payload.error && action.payload.data) {
        setToken(action.payload.data.token);
        state.token = action.payload.data.token

        state.user = action.payload.data.user

        if (
          action.payload.user_data &&
          action.payload.user_data.location_id &&
          action.payload.user_data.location_id.length > 0
        ) {
          state.user.facility_owner = true
        } else {
          state.user.facility_owner = false
        }

        state.isLogged = true
      }
      if (action.payload.error) {
        toast.error(
          'There was an error creating your account. Please try again.',
          { position: 'top-right' }
        )
      } else {
        toast.success('Your account was created successfully.', {
          position: 'top-right',
        })
      }
    })
    asyncReducers(builder, createClient, 'authStatus', (_state, action) => {
      if (action.payload.error) {
        toast.error(
          'There was an error creating your account. Please try again.',
          { position: 'top-right' }
        )
      } else {
        toast.success('Client account was created successfully.', {
          position: 'top-right',
        })
      }
    })
    asyncReducers(builder, resetPassword, 'authStatus', (_state, action) => {
      if (action.payload.error) {
        toast.success('If you have an account, we will send a link to reset your password shortly.', {
          position: 'top-right',
        })
      } else {
        toast.success('If you have an account, we will send a link to reset your password shortly.', {
          position: 'top-right',
        })
      }
    })
  },
})

export default authSlice.reducer
export const authActions = {
  ...authSlice.actions,
  signIn,
  signUp,
  createClient,
  resetPassword,
}
