import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import APILoadingStatus from 'types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import cogoToast from 'cogo-toast'
import { Client } from 'models/client.model'
import {
  getClients,
  getAllClients,
  getClientById,
  getClientPlayers,
  updateClientById,
} from './clients.actions'

export type ClientState = {
  loading: APILoadingStatus
  playersLoading: APILoadingStatus
  clients: Client[] | null
  players: Client[] | null
  currentPage: number
  totalClients: number
  totalCount: number
  currentClient: Client | null
}

const initialState: ClientState = {
  loading: APILoadingStatus.Idle,
  playersLoading: APILoadingStatus.Idle,
  clients: null,
  currentPage: 1,
  totalClients: 0,
  totalCount: 0,
  currentClient: null,
  players: null,
}

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setCurrentClient(state, action: PayloadAction<Client>) {
      state.currentClient = action.payload
    },
  },
  extraReducers: builder => {
    asyncReducers(builder, getClients, 'loading', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        state.clients = action.payload.data
      }
    })
    asyncReducers(builder, getAllClients, 'loading', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        state.totalClients = action.payload.description
      }
    })
    asyncReducers(
      builder,
      getClientPlayers,
      'playersLoading',
      (state, action) => {
        if (action.payload.error) {
          cogoToast.error(action.payload.error, {
            position: 'top-right',
          })
        } else {
          state.players = action.payload.data
        }
      }
    )
    asyncReducers(builder, getClientById, 'loading', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        state.currentClient = action.payload.data
      }
    })
    asyncReducers(builder, updateClientById, 'loading', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        state.currentClient = action.payload.data
      }
    })
  },
})

export default clientSlice.reducer
export const clientActions = {
  ...clientSlice.actions,
  ...clientSlice.reducer,
  getClients,
  getAllClients,
  getClientById,
  getClientPlayers,
  updateClientById,
}
