import {
  CreateClientRequest,
  CreateClientResponse,
  SignInRequest,
  SignInResponse,
} from './auth.types'
import axios from '../base'
import { SignUpRequest, SignUpResponse } from '.'
import { handleError } from 'utils/error'

export class AuthService {
  signIn = async (fields: SignInRequest): Promise<SignInResponse> => {
    const { data } = await axios.post<SignInResponse>('login', fields)
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error("We didn't recognize your credentials. Please try again.")
      )
      return {} as SignInResponse
    } else {
      return data
    }
  }
  signUp = async (fields: SignUpRequest): Promise<SignUpResponse> => {
    const { data } = await axios.post<SignUpResponse>('signup', {
      first_name: fields.firstName,
      last_name: fields.lastName,
      email: fields.email,
      password: fields.password,
    })
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error on signUp. Please try again.')
      )
      return {} as SignUpResponse
    } else {
      return data
    }
  }
  createClient = async (
    fields: CreateClientRequest
  ): Promise<CreateClientResponse> => {
    const { data } = await axios.post<CreateClientResponse>('signup', fields)
    if (data.error) {
      handleError(
        data.description
          ? Error(data.description)
          : Error('Error on signUp. Please try again.')
      )
      return {} as CreateClientResponse
    } else {
      return data
    }
  }
  resetPassword = async (fields: {
    userId: string
    locationId?: string
    email?: string
  }): Promise<CreateClientResponse> => {
    const { userId, locationId, email } = fields;
    const { data } = await axios.post<CreateClientResponse>(
      locationId ? `/users/${userId}/reset-password/${locationId}` : `/users/${userId}/reset-password`,
      fields
    );
    return data;
  }
}

export default new AuthService()
