import moment from 'moment'
import { handleError } from 'utils/error'
import axios from '../base'
import { PostProgramRequest } from './program.types'
import {
  CreateProgramRegistrationRequest,
  CreateProgramRegistrationResponse,
  GetProgramByIdRequest,
  GetProgramByIdResponse,
  GetProgramPublicResponse,
  GetProgramScheduleRequest,
  GetProgramScheduleResponse,
  GetProgramsPublicRequest,
  GetProgramsRequest,
  GetProgramsResponse,
} from './program.types'

export class ProgramService {
  private url = '/locations'

  getPrograms = async (
    fields: GetProgramsRequest
  ): Promise<GetProgramsResponse> => {
    const {
      locationId,
      page,
      limit,
      search,
      endDate,
      visible,
      discarded,
    } = fields
    let endpoint_url = `${this.url}/${locationId}/programs?discarded=${discarded}&visible=${visible}`
    if (page) {
      endpoint_url += `&page=${page}`
    }
    if (limit) {
      endpoint_url += `&limit=${limit}`
    }
    if (search) {
      endpoint_url += `&search_text=${search}`
    }
    if (endDate) {
      endpoint_url += `&end_date=${moment(new Date()).format('yyyy-MM-DD')}`
    }
    const { data } = await axios.get<GetProgramsResponse>(endpoint_url)
    if (data.error) {
      handleError(Error('Error en getPrograms'))
      return {} as GetProgramsResponse
    } else {
      return data
    }
  }

  getProgramById = async (fields: GetProgramByIdRequest) => {
    const { locationId, programId } = fields
    const { data } = await axios.get<GetProgramByIdResponse>(
      `${this.url}/${locationId}/programs/${programId}`
    )
    if (data.error) {
      handleError(Error('Error en getProgramById'))
      return {} as GetProgramByIdResponse
    } else {
      return data
    }
  }

  createProgram = async (fields: PostProgramRequest) => {
    const { locationId, body } = fields
    const { data } = await axios.post<GetProgramByIdResponse>(
      `${this.url}/${locationId}/programs`,
      body
    )
    if (data.error) {
      handleError(Error('Error en createProgram'))
      return {} as GetProgramByIdResponse
    } else {
      return data
    }
  }

  getProgramScheduleById = async (
    fields: GetProgramScheduleRequest
  ): Promise<GetProgramScheduleResponse> => {
    const { locationId, programId } = fields
    const { data } = await axios.get<GetProgramScheduleResponse>(
      `${this.url}/${locationId}/appointments?calendar_formatting=true&program_id=${programId}`
    )
    if (data.error) {
      handleError(Error('Error en getProgramScheduleById'))
      return {} as GetProgramScheduleResponse
    } else {
      return data
    }
  }

  getPublicPrograms = async (
    fields: GetProgramsPublicRequest
  ): Promise<GetProgramPublicResponse> => {
    const { locationId, programIds, endDate, searchText } = fields
    const program_ids = programIds || []
    const { data } = await axios.post<GetProgramPublicResponse>(
      `${this.url}/${locationId}/programs/public_programs`,
      {
        end_date: endDate,
        program_ids,
        search_text: searchText,
      }
    )
    return data
  }

  createProgramRegistration = async (
    fields: CreateProgramRegistrationRequest
  ): Promise<CreateProgramRegistrationResponse> => {
    const { locationId, programId, userId } = fields
    const { data } = await axios.post<CreateProgramRegistrationResponse>(
      `${this.url}/${locationId}/programs/${programId}/registrations/${userId}`
    )
    if (data.error) {
      handleError(Error('Error en getProgramScheduleById'))
      return {} as CreateProgramRegistrationResponse
    } else {
      return data
    }
  }
}

export default new ProgramService()
