import { createAsyncThunk } from '@reduxjs/toolkit'
import programService from 'services/programs'

export const getPrograms = createAsyncThunk(
  'programs/get-programs',
  programService.getPrograms
)
export const getProgramById = createAsyncThunk(
  'programs/get-program-by-id',
  programService.getProgramById
)

export const createProgram = createAsyncThunk(
  'programs/create-program',
  programService.createProgram
)

export const getProgramScheduleById = createAsyncThunk(
  'programs/get-program-schedule-by-id',
  programService.getProgramScheduleById
)

export const getPublicPrograms = createAsyncThunk(
  'programs/get-public-programs',
  programService.getPublicPrograms
)

export const createProgramRegistration = createAsyncThunk(
  'programs/create-program-registration',
  programService.createProgramRegistration
)
