import { createSlice } from '@reduxjs/toolkit'
import APILoadingStatus from 'types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import { sendEmails, sendMessages } from './outreach.actions'
import cogoToast from 'cogo-toast'

export type OutreachState = {
  messageStatus: APILoadingStatus
  emailStatus: APILoadingStatus
}

const initialState: OutreachState = {
  messageStatus: APILoadingStatus.Idle,
  emailStatus: APILoadingStatus.Idle,
}

const outreachSlice = createSlice({
  name: 'outreach',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, sendMessages, 'messageStatus', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        cogoToast.success('Your message was sent.', {
          position: 'top-right',
        })
      }
    })
    asyncReducers(builder, sendEmails, 'emailStatus', (state, action) => {
      if (action.payload.error) {
        cogoToast.error(action.payload.error, {
          position: 'top-right',
        })
      } else {
        cogoToast.success('Your message was sent.', {
          position: 'top-right',
        })
      }
    })
  },
})

export default outreachSlice.reducer
export const outreachActions = {
  ...outreachSlice.actions,
  sendMessages,
  sendEmails,
}
