import { handleError } from 'utils/error'
import { WaiverStatusRequest, WaiverStatusResponse } from '.'
import axios from '../base'

export class WaiverService {
  private url = '/locations'

  getWaiverStatusForUser = async (
    fields: WaiverStatusRequest
  ): Promise<WaiverStatusResponse | undefined> => {
    const { locationId, userId } = fields
    const { data } = await axios.get(
      `${this.url}/${locationId}/waivers/${userId}`
    )
    if (data.error) {
      handleError(
        Error(
          'We encountered an error getting the waiver status. Please contact support.'
        )
      )
      return undefined
    } else {
      return data as WaiverStatusResponse
    }
  }

  postWaiverStatusForUser = async (
    fields: WaiverStatusRequest
  ): Promise<WaiverStatusResponse | undefined> => {
    const { locationId, userId } = fields
    const { data } = await axios.post(
      `${this.url}/${locationId}/waivers/${userId}`
    )
    if (data.error) {
      handleError(
        Error(
          'We encountered an error post the waiver status. Please contact support.'
        )
      )
      return undefined
    } else {
      return data as WaiverStatusResponse
    }
  }
}

export default new WaiverService()
