import React, { createContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { THEME } from './enums';
import { themes } from './styled';

interface IProps {
	children: React.ReactChild;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChangeThemeContext = createContext((_: THEME) => {
	// Empty arrow function
});

export default ({ children }: IProps) => {
	const [themeName, changeThemeName] = useState(THEME.Light);
	const theme = themes[themeName];

	return (
		<ChangeThemeContext.Provider value={changeThemeName}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ChangeThemeContext.Provider>
	);
};
