import { handleError } from 'utils/error'
import axios from '../base'
import {
  EmailOutreachRequest,
  MessageOutreachRequest,
  OutreachResponse,
} from './outreach.types'

export class OutreachService {
  private url = '/communications'

  sendEmails = async (
    fields: EmailOutreachRequest
  ): Promise<OutreachResponse> => {
    const { locationId, body } = fields
    const { data } = await axios.post<OutreachResponse>(
      `${this.url}/${locationId}/emails/send_emails`,
      body
    )
    if (data.error) {
      handleError(data.description ? Error(data.description) : Error("Error en sendEmails"));
      return {} as OutreachResponse;
    } else {
      return data;
    }
  }

  sendMessages = async (
    fields: MessageOutreachRequest
  ): Promise<OutreachResponse> => {
    const { locationId, body } = fields
    const { data } = await axios.post<OutreachResponse>(
      `${this.url}/${locationId}/messages/send_messages`,
      body
    )
    if (data.error) {
      handleError(data.description ? Error(data.description) : Error("Error en sendMessages"));
      return {} as OutreachResponse;
    } else {
      return data;
    }
  }
}

export default new OutreachService()
