import React from 'react'
import { PropagateLoader } from 'react-spinners'
import { themes } from 'theme/styled'
import { SpinnerProps } from './Spinner.props'
import { SpinnerContainer } from './Spinner.styled'

const Spinner = ({ loading }: SpinnerProps) => {
  const color = themes.Light.colors.primary

  return (
    <SpinnerContainer>
      <PropagateLoader color={color} loading={loading ? loading : true} />
    </SpinnerContainer>
  )
}

export default Spinner
