import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserProfile, UserPastPurchase } from 'models/user.model'

import APILoadingStatus from 'types/api-loading-status'
import {
  getUserProfile,
  getUserTransactionsByLocation,
  getUserBalance,
  getUserAppointments,
  setUserFamily,
  getWaiverStatusForUser,
  postWaiverStatusForUser,
} from './users.actions'
import asyncReducers from '../../../utils/async-reducers'
import {
  UserBalanceResponse,
  UserTransactionsByLocationResponse,
} from 'services/user/user.types'
import { Appointment } from 'services/appointment'
import cogoToast from 'cogo-toast'
import { WaiverStatusResponse } from 'services/waiver'

export type UserState = {
  loading: APILoadingStatus
  loadingUserTransaction: APILoadingStatus
  userProfile: UserProfile | null
  userTransactions: UserPastPurchase[] | null
  userBalance: number | null
  userAppointments: Appointment[]
  waiverSigned: boolean
  waiverUrl: string | undefined
}

const initialState: UserState = {
  loading: APILoadingStatus.Idle,
  loadingUserTransaction: APILoadingStatus.Idle,
  userProfile: null,
  userTransactions: null,
  userBalance: null,
  userAppointments: [],
  waiverUrl: undefined,
  waiverSigned: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserTransactions(state) {
      state.userTransactions = null
      state.userBalance = null
      state.userAppointments = []
    },
  },
  extraReducers: builder => {
    asyncReducers(
      builder,
      setUserFamily,
      'loading',
      (state, action: PayloadAction<UserProfile | undefined>) => {
        if (action.payload && action.payload) {
          state.userProfile = action.payload
          cogoToast.success('Relation registered successfully', {
            position: 'top-right',
          })
        }
      }
    )
    asyncReducers(
      builder,
      getUserAppointments,
      'loading',
      (state, action: PayloadAction<Appointment[]>) => {
        if (action.payload && action.payload.length > 0) {
          state.userAppointments = action.payload
        }
      }
    )
    asyncReducers(
      builder,
      getUserProfile,
      'loading',
      (state, action: PayloadAction<UserProfile>) => {
        if (action.payload && !action.payload.error) {
          state.userProfile = action.payload
        }
      }
    )
    asyncReducers(
      builder,
      getUserTransactionsByLocation,
      'loadingUserTransaction',
      (state, action: PayloadAction<UserTransactionsByLocationResponse>) => {
        if (action.payload && !action.payload.error) {
          state.userTransactions = action.payload.data
        }
      }
    )
    asyncReducers(
      builder,
      getUserBalance,
      'loading',
      (state, action: PayloadAction<UserBalanceResponse>) => {
        if (action.payload && !action.payload.error) {
          state.userBalance = action.payload.data.balance
        }
      }
    )
    asyncReducers(
      builder,
      getWaiverStatusForUser,
      'loading',
      (state, action) => {
        if (action.payload && !action.payload.error) {
          const { waiver_signed, waiver_url } = action.payload.data
          ;(state.waiverSigned = waiver_signed),
            (state.waiverUrl = waiver_url ?? undefined)
        }
      }
    ),
      asyncReducers(
        builder,
        postWaiverStatusForUser,
        'loading',
        (state, action: any) => {
          if (action.payload.data) {
            const { waiver_signed, waiver_url } = action.payload.data
            ;(state.waiverSigned = waiver_signed),
              (state.waiverUrl = waiver_url ?? undefined)
          }
          if (action.payload.error) {
            cogoToast.error(action.payload.error, {
              position: 'top-right',
            })
          }
        }
      )
  },
})

export default userSlice.reducer
export const userActions = {
  ...userSlice.actions,
  ...userSlice.reducer,
  getUserProfile,
  getUserBalance,
  getUserTransactionsByLocation,
  getUserAppointments,
  setUserFamily,
  getWaiverStatusForUser,
  postWaiverStatusForUser,
}
