// tokenManager.ts

let authToken: string | null = null

export function setToken(token: string) {
  authToken = token
}

export function getToken() {
  return authToken
}
