import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IState {
  sidebarOpen: boolean
}

const initialState: IState = {
  sidebarOpen: false,
}

const { reducer, actions } = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload
    },
  },
})

export default reducer
export const layoutActions = actions
