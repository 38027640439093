import { createAsyncThunk } from '@reduxjs/toolkit'
import clientService from 'services/clients'

export const getClients = createAsyncThunk(
  'clients/get-clients',
  clientService.getClientList
)

export const getAllClients = createAsyncThunk(
  'clients/get-all-clients',
  clientService.getClientList
)
export const getClientPlayers = createAsyncThunk(
  'clients/get-clients-players',
  clientService.getClientList
)

export const getClientById = createAsyncThunk(
  'clients/get-client-byId',
  clientService.getClientById
)
export const updateClientById = createAsyncThunk(
  'clients/update-client-byId',
  clientService.updateClientById
)
